export enum ContactFormFields {
	NAME = 'user_name',
	MOBILE = 'user_contact',
	EMAIL = 'user_email',
	MESSAGE = 'message',
}

export enum VolunteerFormFields {
	NAME = 'user_name',
	MOBILE = 'user_contact',
	EMAIL = 'user_email',
	ADDRESS_LINE_1 = 'address_line_1',
	ADDRESS_LINE_2 = 'address_line_2',
	CITY = 'user_city',
	STATE = 'user_state',
	PINCODE = 'user_pincode',
	LOCATION_LINK = 'location_link',
	MESSAGE = 'message',
}

export enum SECTION_VIEW_TRACKING {
	HERO_SECTION = 'HERO_SECTION',
	WEATHER_SYSTEM_INFO_SECTION = 'WEATHER_SYSTEM_INFO_SECTION',
	API_CALLOUT_SECTION = 'API_CALLOUT_SECTION',
	ABOUT_US_SECTION = 'ABOUT_US_SECTION',
	WEATHER_SYSTEM_WORKING_SECTION = 'WEATHER_SYSTEM_WORKING_SECTION',
	MAP_VIEW_SECTION = 'MAP_VIEW_SECTION',
	TRENDS_N_FACTS_SECTION = 'TRENDS_N_FACTS_SECTION',
	VOLUNTEER_SECTION = 'VOLUNTEER_SECTION',
	GET_IN_TOUCH_SECTION = 'GET_IN_TOUCH_SECTION',
	FOOTER_SECTION = 'FOOTER_SECTION',
}

export enum DASHBOARD_VIEW_TRACKING {
	USER_STATS_SECTION_LOGGED_OUT = 'USER_STATS_SECTION_LOGGED_OUT',
	USER_STATS_SECTION_LOGGED_IN = 'USER_STATS_SECTION_LOGGED_IN',
	USER_STATS_UNREGISTERED = 'USER_STATS_UNREGISTERED',
	API_DOCS_SECTION = 'API_DOCS_SECTION',
	WEBHOOKS_USER_SECTION_UNREGISTERED = 'WEBHOOKS_SECTION_UNREGISTERED',
	WEBHOOKS_USER_SECTION_LOGGED_OUT = 'WEBHOOKS_USER_SECTION_LOGGED_OUT',
	WEBHOOKS_USER_SECTION_SECTION_LOGGED_IN = 'WEBHOOKS_USER_SECTION_SECTION_LOGGED_IN',
	WEBHOOKS_USER_SECTION_CREATE_WEBHOOK = 'WEBHOOKS_USER_SECTION_CREATE_WEBHOOK',
	WEBHOOKS_USER_SECTION_WEBHOOKS_INFO = 'WEBHOOKS_USER_SECTION_WEBHOOKS_INFO',
	WEBHOOKS_USER_SECTION_REJECTED = 'WEBHOOKS_USER_SECTION_REJECTED',
}

export enum OnboardingFormFields {
	USER_NAME = 'user_name',
	USER_TYPE = 'user_purpose',
}

export enum WeatherFactTypes {
	HIGHEST_RAIN_ACCUMULATION = 'HIGHEST_RAIN_ACCUMULATION',
	HIGHEST_TEMPERATURE = 'HIGHEST_TEMPERATURE',
	LOWEST_TEMPERATURE = 'LOWEST_TEMPERATURE',
	MAX_WIND_SPEED = 'MAX_WIND_SPEED',
}

export enum SmallWeatherFacts {
	AVERAGE_TEMPERATURE_MIN = 'AVERAGE_TEMPERATURE_MIN',
	AVERAGE_TEMPERATURE_MAX = 'AVERAGE_TEMPERATURE_MAX',
	AVERAGE_RAIN_ACCUMULATION = 'AVERAGE_RAIN_ACCUMULATION',
	LOWEST_TEMPERATURE = 'LOWEST_TEMPERATURE',
	HIGHEST_TEMPERATURE = 'HIGHEST_TEMPERATURE',
	HIGHEST_RAIN_ACCUMULATION = 'HIGHEST_RAIN_ACCUMULATION',
	LOCALITY_MAX_TEMPERATURE = 'LOCALITY_MAX_TEMPERATURE',
	LOCALITY_MIN_TEMPERATURE = 'LOCALITY_MIN_TEMPERATURE',
	LOCALITY_RAIN_ACCUMULATION = 'LOCALITY_RAIN_ACCUMULATION',
}

export enum WeatherModes {
	TEMPERATURE = 'temperature',
	RAIN_ACCUMULATION = 'rain_accumulation',
	HUMIDITY = 'humidity',
	WIND = 'wind_speed',
	RAIN_INTENSITY = 'rain_intensity',
}

export enum WeatherDeviceType {
	UNKNOWN = 0,
	ZWS = 1,
	RAIN_GAUGE = 2,
}
