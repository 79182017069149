export const Storage = {
	getSyncLocalStorage() {
		return {
			readSync(key: string) {
				return localStorage.getItem(key);
			},
			updateSync(key: string, value: string) {
				localStorage.setItem(key, value);
			},
		};
	},
	isLocalStorageAvailable() {
		try {
			localStorage.setItem('ACCESS_CHECK', 'test');
			localStorage.removeItem('ACCESS_CHECK');
			return true;
		} catch {
			return false;
		}
	},
};
