import { DEVICE_ID_KEY } from '../constants';
import { generateUUID } from './crypto-id';
import { Storage } from './storage';

export const getDeviceId = () => {
	if (typeof window === 'undefined') return generateUUID();

	if (Storage.isLocalStorageAvailable() === false) return generateUUID();

	const localStorage = Storage.getSyncLocalStorage();
	const zomDeviceId = localStorage.readSync(DEVICE_ID_KEY);

	if (typeof zomDeviceId === 'string') {
		localStorage.updateSync(DEVICE_ID_KEY, zomDeviceId);
		return zomDeviceId;
	} else {
		const newDeviceId = generateUUID();
		localStorage.updateSync(DEVICE_ID_KEY, newDeviceId);
		return newDeviceId;
	}
};
