export function convertToObjectString(obj: Record<string, any>): Record<string, string> {
	const result: Record<string, string> = {};

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key];
			if (typeof value === 'object' && value !== null) {
				result[key] = JSON.stringify(value);
			} else {
				result[key] = String(value);
			}
		}
	}

	return result;
}
