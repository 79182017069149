import { Jumbo } from '@zomato/jumbo-client';
import { usePathname } from 'next/navigation';
import { useCallback } from 'react';

import { getDeviceId } from './utils/device-id';
import { getAppSession } from './utils/app-session';
import { JUMBO_WEATHER_TABLE_NAME } from './constants';
import { getBrowserInfo } from './utils/browser';
import { convertToObjectString } from './utils/flatten-object';
import { getEnvironment } from './utils/environment';

export const EVENT_TYPE = {
	UNSPECIFIED: 'EVENT_TYPE_UNSPECIFIED',
	CLICK: 'EVENT_TYPE_CLICK',
	IMPRESSION: 'EVENT_TYPE_IMPRESSION',
	PAGE_LAUNCH: 'EVENT_TYPE_PAGE_LAUNCH',
	TYPING: 'EVENT_TYPE_TYPING',
	RECEIVE: 'EVENT_TYPE_RECEIVE',
	COPY_TO_CLIPBOARD: 'EVENT_TYPE_COPY_TO_CLIPBOARD',
	SHARE: 'EVENT_TYPE_SHARE',
	ERROR_BOUNDARY: 'EVENT_TYPE_ERROR_BOUNDARY',
};

export interface UseTrackerProps {
	// source component like new-order-modal / active-order-card / o2-layout
	ui_source?: string;
	// widget on which event has occurred like order-accept-button / onboarding-doc-submit / kot-button
	component?: string;
	// meta keys would be added like ticket IDs, filters used
	meta_data?: object;
	// event types like CLICK / IMPRESSION / PAGE_LAUNCH / TYPING / RECEIVE
	event_type: keyof typeof EVENT_TYPE;
}

interface WebTrackingPayload extends UseTrackerProps {
	// product release version like v0.1/v0.2/v0.3/v0.4
	version: string;
	// browser name like chrome / firefox / safari
	browser_name: string;
	// browser versions like 112.0.0.1 / 604.1
	browser_version: string;
	// pathname like /dining/invite or onlineordering/menu or onlineordering/reporing
	url_segment?: string;
}

let jumbo: Jumbo | null = null;

if (typeof window !== 'undefined') {
	/**
	 * DEV: Currently in jumbo we cant create instance by ourself
	 * because constructor is private and we are not able to create
	 * instance, we can't use default because it has some default
	 * init calls which we don't need. This works!
	 */
	// @ts-ignore
	const jumboInstance: Jumbo = new Jumbo();

	jumbo = jumboInstance;
	jumbo.setParams({
		session_id: getAppSession().id,
		device_id: getDeviceId(),
	});
}

const browserInfo = getBrowserInfo();
const environment = getEnvironment();

/**
 * @see https://github.com/Zomato/jumbo-event-registry/blob/master/proto/jumbo/eventregistry/zomato/web/weatherwebtracking/weather_web_tracking.proto#L18-L29
 * @see https://github.com/Zomato/jumbo-event-registry/blob/master/proto/jumbo/eventregistry/zomato/platform/tracking/web/web_tracking.proto
 */
export function useTracker() {
	const pathname = usePathname();

	const trackEvent = useCallback(
		({ event_type, ...props }: UseTrackerProps) => {
			if (!jumbo) {
				return;
			}

			if (props.meta_data) {
				props.meta_data = convertToObjectString(props.meta_data);
			}

			const payloadToPost: WebTrackingPayload = {
				browser_name: browserInfo.name,
				browser_version: browserInfo.version,
				version: `${environment.environment}::${environment.build}`,
				url_segment: pathname,
				event_type: EVENT_TYPE[event_type] as keyof typeof EVENT_TYPE,
				...props,
			};

			if (process.env.NEXT_PUBLIC_APP_ENV !== 'prod') {
				console.debug('[DEBUG] Tracking Events :: ', payloadToPost);
				return;
			}

			jumbo.sendToJumbo(JUMBO_WEATHER_TABLE_NAME, {
				web_tracking: payloadToPost,
			});
		},
		[pathname],
	);

	return {
		trackEvent,
	};
}

interface SetJumboParamProps {
	session_id?: string;
	device_id?: string;
	user_id?: number;
}

export function setJumboParams({ device_id, session_id, user_id }: SetJumboParamProps) {
	if (!jumbo) {
		console.error('Jumbo instance not found cannot set params');
		return;
	}

	const sessionId = session_id ?? jumbo.getSessionId();
	const deviceId = device_id ?? jumbo.getDeviceId();
	const userId = user_id;

	jumbo.setParams({
		session_id: sessionId,
		device_id: deviceId,
		user_id: userId,
	});
}
