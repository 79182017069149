import { isBrowser } from '@/lib/utils';

export interface BrowserInfo {
	name: string;
	version: string;
	userAgent: string;
}

export function getBrowserInfo(): BrowserInfo {
	if (!isBrowser) {
		return { name: '', version: '', userAgent: '' };
	}

	const userAgent = navigator.userAgent;
	let name = '';
	let version = '';

	if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) {
		name = 'Internet Explorer';
		// Finding the version from the user agent string
		const match = /MSIE\s(\d+)|rv:(\d+)/.exec(userAgent);
		if (match) {
			version = match[1] || match[2];
		}
	}
	// Checking if the browser is Edge
	else if (userAgent.indexOf('Edge') !== -1) {
		name = 'Microsoft Edge';
		// Finding the version from the user agent string
		const match = /Edge\/(\d+)/.exec(userAgent);
		if (match) {
			version = match[1];
		}
	}
	// Checking if the browser is Chrome
	else if (userAgent.indexOf('Chrome') !== -1) {
		name = 'Google Chrome';
		// Finding the version from the user agent string
		const match = /Chrome\/(\d+)/.exec(userAgent);
		if (match) {
			version = match[1];
		}
	}
	// Checking if the browser is Firefox
	else if (userAgent.indexOf('Firefox') !== -1) {
		name = 'Mozilla Firefox';
		// Finding the version from the user agent string
		const match = /Firefox\/(\d+)/.exec(userAgent);
		if (match) {
			version = match[1];
		}
	}
	// Checking if the browser is Safari
	else if (userAgent.indexOf('Safari') !== -1) {
		name = 'Safari';
		// Finding the version from the user agent string
		const match = /Version\/(\d+)/.exec(userAgent);
		if (match) {
			version = match[1];
		}
	}
	// If none of the above conditions match, assign unknown
	else {
		name = 'Unknown Browser';
		version = 'Unknown Version';
	}

	return { name, version, userAgent };
}
