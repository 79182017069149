import { useEffect, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { useAuthData, useRegistrationData } from '@/api/user';
import { useToast } from '@/ui/use-toast';
import { CLIENT_ROUTES, DEFAULT_API_ERROR_TITLE } from '@/lib/constants';
import { useAppStore } from '@/lib/store';

const ENABLED_PROTECTION_REDIRECTS = true;

export function useZomatoUser() {
	const { data: zomatoAuthData, isLoading, isError, error } = useAuthData();
	const { data: registrationData, isLoading: isRegistrationLoading } = useRegistrationData();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isRegistered, setIsRegistered] = useState(false);
	const [isAuthLoading, setIsAuthLoading] = useState(true);
	const setAuthSdkState = useAppStore(state => state.setAuthSdkState);

	const { toast } = useToast();
	const router = useRouter();
	const pathname = usePathname();
	const params = useSearchParams();

	function isUserZoman() {
		if (isLoggedIn && zomatoAuthData && zomatoAuthData.response.email.indexOf('@zomato.com') > -1) {
			return true;
		}
	}

	useEffect(() => {
		if (isLoading || isRegistrationLoading) {
			return;
		}

		const _isLoggedIn =
			(zomatoAuthData &&
				zomatoAuthData.status.status === 'success' &&
				zomatoAuthData.response.is_logged_in === true) ??
			false;
		const _isRegistered = (registrationData && registrationData.is_registered_user === true) ?? false;
		const pageConfig = getPageConfigByPath(pathname);

		function routerReplace(url: string) {
			if (ENABLED_PROTECTION_REDIRECTS) {
				// DEV: use `router.replace` instead of `router.push` to avoid adding a new entry to the history stack
				return router.replace(url);
			}
		}

		if (_isLoggedIn) {
			setIsLoggedIn(_isLoggedIn);
			setIsRegistered(_isRegistered);
			setAuthSdkState('login-success');

			if (pathname === CLIENT_ROUTES.LOGIN.PATH && _isLoggedIn) {
				const callbackUrl = parseCallbackUrl(params.get('callbackUrl'));

				if (_isRegistered && callbackUrl === CLIENT_ROUTES.ONBOARDING.PATH) {
					return routerReplace('/');
				} else if (!_isRegistered) {
					return routerReplace('/onboarding');
				}

				return routerReplace(callbackUrl);
			}

			if (pathname === CLIENT_ROUTES.ONBOARDING.PATH && _isRegistered) {
				return routerReplace('/');
			}

			if (pageConfig?.protected && !_isRegistered && CLIENT_ROUTES.ONBOARDING.PATH !== pathname) {
				return routerReplace(CLIENT_ROUTES.ONBOARDING.PATH);
			}

			if (pageConfig?.protected && CLIENT_ROUTES.LOGIN.PATH === pathname) {
				const callbackUrl = parseCallbackUrl(params.get('callbackUrl'));
				return routerReplace(callbackUrl);
			}

			setIsAuthLoading(false);
			return;
		} else {
			setIsLoggedIn(false);
			setIsRegistered(_isRegistered);
		}

		if (zomatoAuthData && zomatoAuthData.status.status === 'failed') {
			setIsAuthLoading(false);
			toast({
				title: DEFAULT_API_ERROR_TITLE,
				description: 'Failed to fetch user data',
				duration: 2000,
			});
		}

		if (pageConfig?.protected && !_isLoggedIn) {
			setAuthSdkState('login-failure');
			return router.replace(`${CLIENT_ROUTES.LOGIN.PATH}?callbackUrl=${pathname}`);
		}

		setIsAuthLoading(false);
	}, [
		zomatoAuthData,
		isRegistrationLoading,
		setIsLoggedIn,
		error,
		pathname,
		isLoading,
		registrationData,
		isRegistered,
		isLoggedIn,
		setAuthSdkState,
	]);

	return {
		isUserZoman,
		isAuthLoading,
		isRegistered,
		beta: {
			status: registrationData?.user_beta_status || 'PENDING',
			reasonForRejection: registrationData?.reason_for_rejection || null,
		},
		isLoggedIn,
		isLoading,
		isError,
		data: zomatoAuthData,
	};
}

/**
 * DEV: This function is used to parse the callback URL from the query params
 * We should not redirect to an external URL, so we check if the host is the
 * same as the current host in case of failure we redirect to the home page
 */
function parseCallbackUrl(callbackUrl: string | null) {
	try {
		if (!callbackUrl) {
			return '/';
		}

		if (callbackUrl.startsWith('/')) {
			return callbackUrl;
		}

		const callbackUrlObj = new URL(callbackUrl);

		if (callbackUrlObj.host !== window.location.host) {
			console.log('callbackUrlObj.host', callbackUrlObj.host);
			return '/';
		}

		return callbackUrl;
	} catch (error) {
		return '/';
	}
}

function getPageConfigByPath(path: string) {
	const pageConfig = Object.values(CLIENT_ROUTES).find(page => page.PATH === path);
	return pageConfig;
}
