import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Lexend } from 'next/font/google';

import { LANDING_PREFIX, PRODUCT_NAME, SYSTEM_CITY_ID_PREFIX, SYSTEM_LOCALITY_ID_PREFIX } from './constants';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function messageParser(message: string, data: string[]) {
	return message.replace(/%s/g, () => data.shift() as string);
}

export const noop = () => {};

export function on<T extends Window | Document | HTMLElement | EventTarget>(
	obj: T | null,
	...args: Parameters<T['addEventListener']> | [string, (this: T, ev: Event) => any, null, ...any]
): void {
	if (obj && obj.addEventListener) {
		obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>));
	}
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
	obj: T | null,
	...args: Parameters<T['removeEventListener']> | [string, (this: T, ev: Event) => any | null, ...any]
): void {
	if (obj && obj.removeEventListener) {
		obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>));
	}
}

export function clamp(value: number, min: number, max: number): number {
	return Math.min(Math.max(value, min), max);
}

export function convertToUrlSafe(str: string) {
	let urlSafeStr = str.toLowerCase();
	urlSafeStr = urlSafeStr.replace(/[^\w\s-]/g, '');
	urlSafeStr = urlSafeStr.replace(/\s+/g, '-');
	return urlSafeStr;
}

export function weatherEncodeLocation({
	city,
	city_id,
	locality,
	locality_id,
}: {
	city: string;
	city_id: string;
	locality: string;
	locality_id: string;
}) {
	return {
		city: convertToUrlSafe(city),
		city_id: city_id.split(SYSTEM_CITY_ID_PREFIX)[1],
		locality: convertToUrlSafe(locality),
		locality_id: locality_id.split(SYSTEM_LOCALITY_ID_PREFIX)[1],
	};
}

export function weatherDecodeLocation(urlSafe: string) {
	const [_, country, city, city_id, locality, locality_id] = urlSafe.split('/');

	if (!city || !city_id || !locality || !locality_id) {
		return {
			isValid: false,
		};
	}

	return {
		isValid: true,
		city,
		city_id: `${SYSTEM_CITY_ID_PREFIX}${city_id}`,
		locality,
		locality_id: `${SYSTEM_LOCALITY_ID_PREFIX}${locality_id}`,
	};
}

export const isBrowser = typeof window !== 'undefined';

export const lexendFont = Lexend({
	subsets: ['latin'],
	display: 'swap',
	preload: true,
});

export function getCookieValue(key: string) {
	const value = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
	return value;
}

export function cleanJsonString(jsonString: string) {
	return jsonString
		.replace(/\\n/g, '\\n')
		.replace(/\\'/g, "\\'")
		.replace(/\\"/g, '\\"')
		.replace(/\\&/g, '\\&')
		.replace(/\\r/g, '\\r')
		.replace(/\\t/g, '\\t')
		.replace(/\\b/g, '\\b')
		.replace(/\\f/g, '\\f');
}

interface UpdateLocationUrlParams {
	parsedWeatherData: ParsedWeatherData;
	cityId: string;
	localityId: string;
}

export function updateLocationUrl({ cityId, localityId, parsedWeatherData }: UpdateLocationUrlParams) {
	if (!cityId || !localityId || !parsedWeatherData) {
		return;
	}
	const cityData = parsedWeatherData.data[cityId];
	const localityData = cityData.localities[localityId];

	if (!cityData) {
		return console.debug('[DEBUG] :: updateLocationUrl : City not found in parsedWeatherData', {
			cityId,
			localityId,
		});
	}

	if (!localityData) {
		return console.debug('[DEBUG] :: updateLocationUrl : Locality not found in parsedWeatherData', {
			cityId,
			localityId,
		});
	}

	const cityName = cityData.city_name;
	const localityName = localityData.locality_name;

	const encodedLocation = weatherEncodeLocation({
		city: cityName,
		city_id: cityId,
		locality: localityName,
		locality_id: localityId,
	});

	const pusUrl = `/${LANDING_PREFIX}/${encodedLocation.city}/${encodedLocation.city_id}/${encodedLocation.locality}/${encodedLocation.locality_id}`;

	if (window) {
		window.history.pushState({}, '', pusUrl);
		document.title = `Current Weather, ${localityName}, ${cityName}`;
	}
}
