import { CloudRainIcon, DropletsIcon, FlaskRoundIcon, ThermometerIcon, WavesIcon } from 'lucide-react';

import { clamp, isBrowser } from '@/lib/utils';
import { WeatherDeviceType, WeatherModes } from '@/types/constants';

export function getMapControlData(data: ParsedLocalityWeatherData, removeFields: WeatherModes[] = []) {
	const allowedMetrics = Object.keys(METRIC_CONSTANTS).filter(key => !removeFields.includes(key as WeatherModes));

	if (!data) {
		console.warn('No data provided for map control data');
		return [];
	}

	return allowedMetrics.map(key => {
		const metric = METRIC_CONSTANTS[key as WeatherModes];
		const numericValue = metric.getValue(data.weather_metrics);
		const value = metric.parser(numericValue)?.toString() ?? null;
		// TODO: fiixme
		// @ts-ignore
		const percentage = metric.percentage(numericValue);
		const gradient = metric.gradient;

		return {
			label: metric.label,
			unit: metric.unit,
			id: metric.id,
			unitPrefix: metric.unitPrefix,
			icon: metric.icon,
			title: metric.title,
			deviceTypes: metric.deviceTypes,
			value,
			percentage,
			gradient,
		};
	});
}

export function getTrendsControlData(data: WeatherLocality | null, removeFields: WeatherModes[] = []) {
	const allowedMetrics = Object.keys(METRIC_CONSTANTS).filter(key => !removeFields.includes(key as WeatherModes));

	if (!data) {
		if (isBrowser) {
			console.warn('No data provided for trends control data');
		}
		return [];
	}

	return allowedMetrics.map(key => {
		const metric = METRIC_CONSTANTS[key as WeatherModes];
		const gradient = metric.gradient;

		return {
			label: metric.label,
			unit: metric.unit,
			id: metric.id,
			unitPrefix: metric.unitPrefix,
			icon: metric.icon,
			title: metric.title,
			deviceTypes: metric.deviceTypes,
			gradient,
		};
	});
}

export const getWindDirection = (angle: number): string => {
	const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
	const index = Math.round(((angle + 180) % 360) / 45);
	return directions[index];
};

export const METRIC_CONSTANTS = {
	[WeatherModes.TEMPERATURE]: {
		id: WeatherModes.TEMPERATURE,
		title: 'Temperature',
		label: 'temperature',
		unitPrefix: '',
		unit: '°C',
		parser: (value: number | null) => {
			return value != null ? value.toFixed(1) : null;
		},
		deviceTypes: [WeatherDeviceType.ZWS],
		icon: ThermometerIcon,
		getValue: (data: LocalityWeatherDetail) => data.temperature,
		percentage: (value: number) => clamp((value / (52 - -5)) * 100, 0, 100),
		gradient: `linear-gradient(to right, 
            #264CFF,
            #3FA0FF,
            #72D8FF,
            #AAF7FF,
            #E0FFFF,
            #FFFFBF,
            #FFE099,
            #FFAD72,
            #F76D5E,
            #D82632,
            #A50021
        )`,
		minVal: -5,
		maxVal: 52,
	},
	[WeatherModes.RAIN_INTENSITY]: {
		id: WeatherModes.RAIN_INTENSITY,
		title: 'Rain intensity',
		label: 'rain',
		unitPrefix: ' ',
		unit: 'mm/min',
		parser: (value: number | null) => (value !== null ? (value === 0 ? value.toString() : value.toFixed(1)) : null),
		deviceTypes: [WeatherDeviceType.ZWS, WeatherDeviceType.RAIN_GAUGE],
		icon: CloudRainIcon,
		getValue: (data: LocalityWeatherDetail) => data.rain_intensity,
		percentage: (value: number) => clamp((value / (4 - 0)) * 100, 2, 100),
		gradient: null,
		minVal: 0,
		maxVal: 4,
	},
	[WeatherModes.RAIN_ACCUMULATION]: {
		id: WeatherModes.RAIN_ACCUMULATION,
		title: 'Total rainfall',
		label: 'rain',
		unitPrefix: ' ',
		unit: 'mm',
		parser: (value: number | null) => (value !== null ? (value === 0 ? value.toString() : value.toFixed(1)) : null),
		deviceTypes: [WeatherDeviceType.ZWS, WeatherDeviceType.RAIN_GAUGE],
		icon: FlaskRoundIcon,
		getValue: (data: LocalityWeatherDetail) => data.rain_accumulation,
		percentage: (value: number) => clamp((value / (85 - 0)) * 100, 2, 100),
		gradient: null,
		minVal: 0,
		maxVal: 85,
	},

	[WeatherModes.HUMIDITY]: {
		id: WeatherModes.HUMIDITY,
		title: 'Humidity',
		label: 'humidity',
		unitPrefix: '',
		unit: '%',
		parser: (value: number | null) => (value !== null ? value.toFixed(0) : null),
		deviceTypes: [WeatherDeviceType.ZWS],
		icon: DropletsIcon,
		getValue: (data: LocalityWeatherDetail) => data.humidity,
		percentage: (value: number) => clamp((value / (100 - 0)) * 100, 2, 100),
		gradient: null,
		minVal: 0,
		maxVal: 100,
	},
	[WeatherModes.WIND]: {
		id: WeatherModes.WIND,
		title: 'Wind speed & direction',
		label: 'wind',
		unitPrefix: ' ',
		unit: 'km/h',
		parser: (value: number | null) => (value != null ? (value * 3.6).toFixed(1) : null),
		deviceTypes: [WeatherDeviceType.ZWS],
		icon: WavesIcon,
		getValue: (data: LocalityWeatherDetail) => data.wind_speed,
		percentage: (value: number) => clamp((value / (40 / 3.6 - 0)) * 100, 2, 100),
		gradient: null,
		minVal: 0 / 3.6,
		maxVal: 40 / 3.6,
	},
};

export function labelParser(value: string | number, unit: string) {
	return `${value}${unit}`;
}

export function getFallbackValue(controlId: WeatherModes, value: string | number | null, isDowntime: boolean) {
	if (isDowntime || value === null) {
		return {
			value: 'NA',
			isValid: false,
		};
	}

	if (controlId === WeatherModes.RAIN_ACCUMULATION || controlId === WeatherModes.RAIN_INTENSITY) {
		if (value === '0.0') {
			return {
				value: '0',
				isValid: true,
			};
		}

		if (value === null) {
			return {
				value: 'NA',
				isValid: false,
			};
		}
	}

	return {
		value,
		isValid: true,
	};
}

export function reportErrorToNewRelic(
	error: Error | any,
	props: {
		[key: string]: any;
	},
) {
	if (typeof window !== 'undefined' && window.newrelic) {
		window.NREUM.noticeError(error, props);
	}
}

export function reportErrorToDD(
	error: Error | any,
	props: {
		[key: string]: any;
	},
) {
	if (typeof window !== 'undefined' && window.DD_RUM) {
		window.DD_RUM.addError(error, props);
	}
}
