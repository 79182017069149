export const API_HOST = 'https://www.weatherunion.com';
export const API_BASE_PATH = '/gw/weather';
export const PRODUCT_NAME = 'Weather Union';
export const PRODUCT_DESCRIPTION =
	"Check the live weather conditions and trends in your area from India's first crowd-supported weather infrastructure powered by Zomato";
export const SYSTEM_LOCALITY_ID_PREFIX = 'ZWL';
export const SYSTEM_CITY_ID_PREFIX = 'ZWC';
export const BASE_PATH = '/';
export const LANDING_PREFIX = 'now';
export const APPLICATION_HOST = 'https://www.weatherunion.com';
export const DEFAULT_API_ERROR_TITLE = 'Oops! An unexpected error occurred';

export const LINKS = {
	WEATHER_TNC: 'https://b.zmtcdn.com/data/file_assets/4f2b1aeb48ea8c87519e7f2bd652b6811715149430.pdf',
	WEATHER_PRIVACY: 'https://b.zmtcdn.com/data/file_assets/a30e8625c9d0558910ac3c5b0a5ae22d1715149469.pdf',
	API_DOCUMENTATION_LINK: 'https://b.zmtcdn.com/data/file_assets/915d4e8e95d067c47e1ae38d77d572b21715104204.pdf',
	MAIL_ID: 'info@weatherunion.com',
};

export const HERO_SECTION = {
	MOBILE: 'https://b.zmtcdn.com/data/o2_assets/b58ee6fc2d53864d75b9bee3e30bf41f1719823254.png',
	IPAD: 'https://b.zmtcdn.com/data/o2_assets/1d6430125dcba66188f6b27a4b76fdbe1719823254.png',
	DESKTOP: 'https://b.zmtcdn.com/data/o2_assets/aa13e973940d719793f3beb96279552a1719823254.png',
};

export const TOP_WEATHER_CITIES = [
	'ZWC000110',
	'ZWC000136',
	'ZWC000102',
	'ZWC000122',
	'ZWC000121',
	'ZWC000135',
	'ZWC000103',
];

export const CLIENT_ROUTES = {
	LOGIN: {
		PATH: '/login/',
		protected: false,
	},
	DASHBOARD: {
		PATH: '/dashboard/',
		protected: false,
	},
	ONBOARDING: {
		PATH: '/onboarding/',
		protected: true,
	},
};

export const QUERY_CLIENT_KEYS = {
	REGISTRATION_DATA: 'registration_data',
	USER_PUSH_CONFIG: 'push_config',
};
