import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { API_BASE_PATH, API_HOST, QUERY_CLIENT_KEYS } from '@/lib/constants';
import { useAppStore } from '@/lib/store';
import { ContactFormFields, OnboardingFormFields, VolunteerFormFields } from '@/types/constants';

interface ILoginBase {
	message?: string;
	status: {
		status: 'success' | 'failed';
	};
}

interface ILoginSuccess extends ILoginBase {
	response: {
		is_logged_in: boolean;
		user_id: number;
		name: string;
		profile_pic_uri: string;
		email: string;
	};
}

interface ILoginFailure extends ILoginBase {
	status: {
		status: 'failed';
	};
	message: string;
}

// ==================================== GET USER OAUTH DATA ZOMATO

async function getAuthData(): Promise<ILoginSuccess> {
	const response = await axios.post(`${API_HOST}/gw/auth/validate?fetch_user_profile=true`);
	return response.data;
}

export function useAuthData() {
	const authSdkState = useAppStore(state => state.authSdkState);
	return useQuery({
		queryKey: ['auth_data', authSdkState],
		queryFn: getAuthData,
		refetchOnWindowFocus: true,
		retry: 0,
	});
}

// ==================================== GET DETAILS IF USER HAS DONE ONBOARDING

async function getRegistrationData(): Promise<WeatherUserRegistrationApiResponse | null> {
	const response = await axios.get(`${API_HOST}${API_BASE_PATH}/external/v0/user/is_registered_user_check`);
	return response.data;
}

export function useRegistrationData() {
	const authSdkState = useAppStore(state => state.authSdkState);
	return useQuery({
		queryKey: [QUERY_CLIENT_KEYS.REGISTRATION_DATA, authSdkState],
		queryFn: getRegistrationData,
		retry: 0,
		refetchOnWindowFocus: true,
	});
}

// ==================================== CONACTS US FORM API

export interface ContactFormApiProps {
	[ContactFormFields.NAME]: string;
	[ContactFormFields.EMAIL]: string;
	[ContactFormFields.MOBILE]: string;
	[ContactFormFields.MESSAGE]: string;
}

export async function postContactResponse(
	props: ContactFormApiProps,
): Promise<WeatherContactFormApiResponse | AxiosError> {
	const response = await axios.post(`${API_HOST}${API_BASE_PATH}/service/contact_us`, props);
	return response.data;
}

// ==================================== VOLUNTEER FORM API

export interface VolunteerFormApiProps {
	[VolunteerFormFields.NAME]: string;
	[VolunteerFormFields.MOBILE]: string;
	[VolunteerFormFields.EMAIL]: string;
	[VolunteerFormFields.ADDRESS_LINE_1]: string;
	[VolunteerFormFields.ADDRESS_LINE_2]: string;
	[VolunteerFormFields.CITY]: string;
	[VolunteerFormFields.STATE]: string;
	[VolunteerFormFields.PINCODE]: string;
	[VolunteerFormFields.LOCATION_LINK]: string;
	[VolunteerFormFields.MESSAGE]: string;
}

export async function postVolunteerResponse(
	props: VolunteerFormApiProps,
): Promise<WeatherContactFormApiResponse | AxiosError> {
	const response = await axios.post(`${API_HOST}${API_BASE_PATH}/service/contact_us`, props);
	return response.data;
}

// ==================================== USER ONBOARDING FORM API

interface UserOnboardingFormApiProps {
	[OnboardingFormFields.USER_NAME]: string;
	[OnboardingFormFields.USER_TYPE]: 'Business' | 'Personal';
}

export async function postRegisterWeatherUser({
	user_name,
	user_purpose,
}: UserOnboardingFormApiProps): Promise<WeatherUserRegistrationApiResponse> {
	const response = await axios.post(`${API_HOST}${API_BASE_PATH}/external/v0/user/create_weather_profile`, {
		[OnboardingFormFields.USER_NAME]: user_name,
		[OnboardingFormFields.USER_TYPE]: user_purpose,
	});
	return response.data;
}

// ==================================== User Beta API
export type RequestBetaProps = {
	is_user_student: boolean;
	purpose_of_use: string;
};

export async function postRequestBetaAccess({ is_user_student, purpose_of_use }: RequestBetaProps) {
	const response = await axios.post(`${API_HOST}${API_BASE_PATH}/external/v0/user/request_beta_access`, {
		is_user_student,
		purpose_of_use,
	});

	return response.data;
}

export type UserPushConfig = {
	webhook_url: string;
	localities_subscribed: string[] | null;
	/** webhook status
	 * 0 - Disabled
	 * 1 - Enabled
	 */
	webhook_status: 0 | 1;
	challenge_token: string;
};

export type PostPushConfig = {
	webhook_url: string;
	localities_to_subscribe: string[] | null;
	/** webhook status
	 * 0 - Disabled
	 * 1 - Enabled
	 */
	webhook_status: 0 | 1;
};

export async function postCreatePushConfig(params: PostPushConfig) {
	const response = await axios.post(`${API_HOST}${API_BASE_PATH}/external/v0/user/create_push_config`, params);
	return response.data;
}

export async function postUpdatePushConfig(params: PostPushConfig) {
	const response = await axios.post(`${API_HOST}${API_BASE_PATH}/external/v0/user/update_push_config`, params);
	return response.data;
}

export async function getUserPushConfig(): Promise<UserPushConfig> {
	const response = await axios.get(`${API_HOST}${API_BASE_PATH}/external/v0/user/get_push_config`);
	return response.data;
}

export function useUserPushConfig(options: Partial<UndefinedInitialDataOptions<UserPushConfig>>) {
	return useQuery({
		...options,
		queryKey: [QUERY_CLIENT_KEYS.USER_PUSH_CONFIG],
		queryFn: getUserPushConfig,
	});
}
