import { reportErrorToDD } from '@/components/WeatherMap/utils';

import { APP_SESSION_KEY } from '../constants';
import { generateUUID } from './crypto-id';

interface Session {
	id: string;
	createTime: number;
}

export function getAppSession(): Session {
	try {
		const sessionData = sessionStorage.getItem(APP_SESSION_KEY);

		if (sessionData) {
			return JSON.parse(sessionData);
		} else {
			const newSession: Session = {
				id: generateUUID(),
				createTime: Date.now(),
			};

			sessionStorage.setItem(APP_SESSION_KEY, JSON.stringify(newSession));

			return newSession;
		}
	} catch (error) {
		reportErrorToDD(error, {
			message: 'Error while getting app session',
			error,
			handled: true,
		});

		return {
			id: 'NA',
			createTime: 0,
		};
	}
}
