/* eslint-disable no-unused-vars */
import { create } from 'zustand';

import { WeatherModes } from '@/types/constants';

interface AppState {
	mapSelectedMode: WeatherModes | null;
	setMapSelectedMode: (mode: WeatherModes) => void;
	mapLiveLocationEnabled: MapLiveLocationStatus;
	setMapLiveLocationEnabled: (enabled: MapLiveLocationStatus) => void;
	mapLiveLocationStatus: boolean | Error;
	setMapLiveLocationStatus: (status: boolean | Error) => void;
	cityWeatherData: ParseCityWeatherData | null;
	setCityWeatherData: (data: ParseCityWeatherData) => void;
	locationSelectedCity: string | undefined;
	setLocationSelectedCity: (city: string) => void;
	locationSelectedLocality: string | undefined;
	setLocationSelectedLocality: (locality: string | undefined) => void;
	isMapDatLoading: boolean;
	setIsMapDataLoading: (loading: boolean) => void;
	isUserInteractingMap: boolean;
	setIsUserInteractingMap: (isInteracting: boolean) => void;
	parsedWeatherData: ParsedWeatherData;
	setParsedWeatherData: (data: ParsedWeatherData) => void;
	selectedCityData: ParsedCityDetails | null;
	setSelectedCityData: (data: ParsedCityDetails) => void;
	selectedLocalityData: WeatherLocality | null;
	setSelectedLocalityData: (data: WeatherLocality) => void;
	authSdkState: AuthSdkStateType;
	setAuthSdkState: (state: AuthSdkStateType) => void;
	developerOptions: DeveloperOptions;
	setShowDownDevices: (showDownDevices: boolean) => void;
	setLiveWeatherUpdateInterval: (interval: number) => void;
	setShowDeviceDetails: (showDeviceDetails: boolean) => void;
	setKeepSelectedPinOpen: (keepSelectedPinOpen: boolean) => void;
	setDisableMapDragBounds: (disableMapDragBounds: boolean) => void;
	setDisableMapZoomBounds: (disableMapZoomBounds: boolean) => void;
	setShowDeviceCount: (showDeviceCount: boolean) => void;
	setDisableZoomOnSelect: (disableZoomOnSelect: boolean) => void;
}

export const useAppStore = create<AppState>()(set => ({
	mapSelectedMode: null,
	setMapSelectedMode: mode => set({ mapSelectedMode: mode }),
	mapLiveLocationEnabled: 'idle',
	setMapLiveLocationEnabled: enabled => set({ mapLiveLocationEnabled: enabled }),
	mapLiveLocationStatus: false,
	setMapLiveLocationStatus: status => set({ mapLiveLocationStatus: status }),
	cityWeatherData: null,
	setCityWeatherData: data => set({ cityWeatherData: data }),
	locationSelectedCity: undefined,
	setLocationSelectedCity: city => set({ locationSelectedCity: city }),
	locationSelectedLocality: undefined,
	setLocationSelectedLocality: locality => set({ locationSelectedLocality: locality }),
	isMapDatLoading: false,
	setIsMapDataLoading: loading => set({ isMapDatLoading: loading }),
	isUserInteractingMap: false,
	setIsUserInteractingMap: isInteracting => set({ isUserInteractingMap: isInteracting }),
	// @ts-ignore
	parsedWeatherData: null,
	setParsedWeatherData: data => set({ parsedWeatherData: data }),
	selectedCityData: null,
	setSelectedCityData: data => set({ selectedCityData: data }),
	selectedLocalityData: null,
	setSelectedLocalityData: data => set({ selectedLocalityData: data }),
	authSdkState: 'idle',
	setAuthSdkState: state => set({ authSdkState: state }),
	developerOptions: {
		showDownDevices: false,
		liveWeatherupdateInterval: 60,
		showDeviceDetails: false,
		disableMapDragBounds: false,
		disableMapZoomBounds: false,
		keepSelectedPinOpen: false,
		showDeviceCount: false,
		disableZoomOnSelect: false,
	},
	setShowDownDevices: (value: boolean) =>
		set(state => ({
			developerOptions: {
				...state.developerOptions,
				showDownDevices: value,
			},
		})),
	setLiveWeatherUpdateInterval: (liveWeatherupdateInterval: number) =>
		set(state => ({
			developerOptions: {
				...state.developerOptions,
				liveWeatherupdateInterval,
			},
		})),
	setShowDeviceDetails: (showDeviceDetails: boolean) =>
		set(state => ({
			developerOptions: {
				...state.developerOptions,
				showDeviceDetails,
			},
		})),
	setKeepSelectedPinOpen: (keepSelectedPinOpen: boolean) =>
		set(state => ({
			developerOptions: {
				...state.developerOptions,
				keepSelectedPinOpen,
			},
		})),
	setDisableMapDragBounds: (disableMapDragBounds: boolean) =>
		set(state => ({
			developerOptions: {
				...state.developerOptions,
				disableMapDragBounds,
			},
		})),
	setDisableMapZoomBounds: (disableMapZoomBounds: boolean) =>
		set(state => ({
			developerOptions: {
				...state.developerOptions,
				disableMapZoomBounds,
			},
		})),
	setShowDeviceCount: (showDeviceCount: boolean) =>
		set(state => ({
			developerOptions: {
				...state.developerOptions,
				showDeviceCount,
			},
		})),
	setDisableZoomOnSelect: (disableZoomOnSelect: boolean) =>
		set(state => ({
			developerOptions: {
				...state.developerOptions,
				disableZoomOnSelect,
			},
		})),
}));
